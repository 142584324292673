import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import Layout from "../components/layout/layout";
import PageHeader from "../components/header/pageHeader";
import styles from "./styling/opt-out.module.css";

const OptOut = ({ location }) => {
  const [hasCookie, setHasCookie] = useState(false);
  const { pathname } = location;
  const intl = useIntl();

  const setOptOutCookie = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    const name = "tracking_opt_out";
    const value = true;
    const expires = date;
    const domain = ".narando.com";

    document.cookie = `${name}=${value}; expires=${expires}; domain=${domain}; path=/; secure;`;
    setHasCookie(true);
  };

  const cookieIsSet = (name) => {
    const prefix = name + "=";
    const cookies = document.cookie;
    const startOfCookie = cookies.indexOf(prefix);
    const hasCookie = startOfCookie >= 0;

    return hasCookie;
  };

  useEffect(() => {
    const cookieName = "tracking_opt_out";
    const hasCookie = cookieIsSet(cookieName);
    setHasCookie(hasCookie);
  }, []);

  const OptOutButton = () => (
    <button onClick={setOptOutCookie} className={styles.optOutButton}>
      Opt-Out
    </button>
  );

  const CookieAlreadySet = () => (
    <>
      <p className={styles.boldParagraph}>
        <FormattedMessage id="optOut.cookieAlreadySet" />
      </p>
    </>
  );

  return (
    <Layout
      display="true"
      path={pathname}
      title={intl.formatMessage({ id: "optOut.title" })}
    >
      <PageHeader header="optOut.heading" />
      <div className={styles.container}>
        <>
          <p className={styles.paragraph}>
            <FormattedMessage id="optOut.paragraph1" />
          </p>
          <p className={styles.paragraph}>
            <FormattedMessage id="optOut.paragraph2" />
          </p>
          <p className={styles.paragraph}>
            <FormattedMessage id="optOut.paragraph3" />
          </p>
          {hasCookie ? <CookieAlreadySet /> : <OptOutButton />}
        </>
      </div>
      <div className="fillContainer" />
    </Layout>
  );
};

export default OptOut;
